// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marchi-tsx": () => import("./../src/pages/marchi.tsx" /* webpackChunkName: "component---src-pages-marchi-tsx" */),
  "component---src-pages-promozioni-tsx": () => import("./../src/pages/promozioni.tsx" /* webpackChunkName: "component---src-pages-promozioni-tsx" */)
}

