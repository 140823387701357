import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import InnerReducer from './reducers/indexSlice'

const store = configureStore({
  reducer: {
    inner: InnerReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;
