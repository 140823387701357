import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IndexState {
    language: string;
    page: number;
}

const initialState: IndexState = {
    language: 'IT',
    page: 0
}

export const slice = createSlice({
    name: 'index',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload
        },
    },
})

export const { setLanguage, setPage } = slice.actions;

export default slice.reducer
